import { useEffect } from 'react';
import { useCallbackRef } from './use-callback-ref';

export const useTimeout = (callback: () => void, delay: number | null) => {
  const cb = useCallbackRef(callback);

  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }

    const id = setTimeout(cb, delay);

    return () => {
      clearTimeout(id);
    };
  }, [cb, delay]);
};
